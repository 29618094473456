import React from 'react'
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { FiSettings } from 'react-icons/fi';

import { Navbar, Footer, Sidebar, ThemeSettings } from '../../components';
import { 
  Dashboard, 
  Handovers, 
  HandoverItems,
  Preflights, 
  Users, 
  PRAItems,
  Aircraft, 
  Station,
} from '../../pages';

import { useStateContext } from '../../contexts/ContextProvider';
import { Routes, Route } from 'react-router-dom';
import Loading from '../Loading';
import HandoversData from '../HandoversData';
import PreflightsData from '../PreflightsData';


const Home = () => {

  const {activeMenu} = useStateContext();

  return (
    <div className="flex relative dark:bg-main-dark-bg">
    <div className="fixed right-4 bottom-4" style={{zIndex: "1000"}}>
      <TooltipComponent content="Settings" position="Top">
        <button type='button' className='text-3xl p-3 hover:drop-shadow-xl hover:bg-light-gray text-white'
        style={{background: 'blue', borderRadius: '50%'}}>
          <FiSettings />
        </button>
      </TooltipComponent>
    </div>
    { 
      activeMenu ? (  
        <div className='w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white noprint'>
          <Sidebar />
        </div>
      ): 
      <div className='w-0 dark:bg-secondary-dark-bg'>
        <Sidebar />
      </div>
    }

    <div className={
      `dark:bg-main-bg bg-main-bg min-h-screen w-full ${activeMenu ? 'md:ml-72' : 'flex-2'}`}>
        <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full noprint">
          <Navbar />
        </div>
    
    <div>
      <Routes>

        {/* DASHBOARD  */}
        <Route path='/' element={<Dashboard /> }/>
        <Route path='/dashboard' element={<Dashboard /> }/>

        {/* USERS  */}
        <Route path='/users' element={<Users/> } />

        {/* HANDOVER  */}
        <Route path='/handovers' element={<Handovers /> } />
        <Route path='/handoveritems' element={<HandoverItems />} />

        {/* HANDOVERS DATA  */}
        <Route path='/handovers/:id' element={ < HandoversData /> } />

        {/* ASSESSMENTS  */}
        <Route path='/preflights' element={<Preflights /> } />
        <Route path='/preflights/:id' element={<PreflightsData /> } />
        <Route path='/preflightriskassessmentitems' element={<PRAItems /> } />

        {/* AIRCRAFT  */}
        <Route path='/aircraft' element={<Aircraft /> } />

        {/* STATION  */}
        <Route path='/stations' element={<Station /> } />

        {/* LOADING  */}
        <Route path='/loading' element={< Loading />} />



        {/* AUTHENTICATE  */}
        {/* <Route path='/signin' element={<Login />} />
        <Route path='/auth' element={Authenticate} /> */}
      </Routes>
    </div>
    </div>
  </div>
  )
}

export default Home