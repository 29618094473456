import React, { useEffect, useState } from 'react';
import { GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  ContextMenu,
  Toolbar,
  Filter,
  Page,
  ExcelExport,
  PdfExport,
  Edit,
  Inject } from '@syncfusion/ej2-react-grids';



const PRAItems = () => {
  return (
    <div>PRAItems</div>
  )
}

export default PRAItems