import React from 'react';
import Home from './home/Home';
// ROUTE GUARDING 
import { Routes, Route } from 'react-router-dom';
import Login from './auth/Login';
import Register from './auth/Register';
import Recovery from './auth/Recovery';
import ProtectedRoutes from '../components/ProtectedRoutes';

const RootPage = () => {

  return(
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/recover' element={<Recovery />} />
        <Route path='/*' element={<ProtectedRoutes><Home /></ProtectedRoutes>} />
      </Routes>
  );

}

export default RootPage