import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

import './index.css';
import App from './App';

import { ContextProvider } from './contexts/ContextProvider';
import { AuthContextProvider } from './contexts/AuthContext';
import { FirestoreContextProvider } from './contexts/DatabaseContext';

createRoot(
    document.getElementById('root')
).render(
    <AuthContextProvider>
        <ContextProvider>
            <FirestoreContextProvider>
                <App />
            </FirestoreContextProvider>
        </ContextProvider>
    </AuthContextProvider>
);

