import React, { useEffect } from 'react';
import { GridComponent,
   ColumnsDirective,
   ColumnDirective,
   Resize,
   Sort,
   ContextMenu,
   Toolbar,
   Filter,
   Page,
   ExcelExport,
   PdfExport,
   Edit,
   Inject } from '@syncfusion/ej2-react-grids';

import { ordersData, contextMenuItems, ordersGrid} from '../data/dummy';
import { aircraftData, aircraftGrid } from '../data/aircraft';

import { Header } from '../components';
import { useState } from 'react';
import { DatabaseService } from '../contexts/DatabaseContext';

const Aircraft = () => {

  const { getAircraft } = DatabaseService();

  // STATE 
  const [aircraft, setAircraft] = useState([]);

  const fetchAircraft = async () => {
    try{
      const aircraftList = await getAircraft();
      const finalAircraftList = aircraftList.docs.map((doc) => (doc.data()));
      setAircraft(finalAircraftList);
    }catch(err){
      console.log(err.message);
    }
  }

  useEffect(() => {
    fetchAircraft();
  }, []); 



  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <Header category='Page' title='Aircraft'/>
      <GridComponent 
      id='gridComponent'
      dataSource={aircraft}
      allowPaging
      allowSorting
      toolbar={['ExcelExport']}
      allowExcelExport>
        <ColumnsDirective onClick={() => alert('index')}  >
            { aircraftGrid.map((item, index) => (
              <ColumnDirective    key={index} {...item}/>
            ))}
          </ColumnsDirective>
          <Inject services={[Toolbar, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit, PdfExport]}/>
        </GridComponent>
      
    </div>
  )
}

export default Aircraft