
export const handoverItemsGrid = [
    {
        field: 'handover_item_code',
        headerText: 'Code',
        textAlign: 'Center',
        width: '120'
    },
    {
        field: 'handover_item_title',
        headerText: 'Title',
        textAlign: 'Left',
        width: '120'
    },
    {
        field: 'handover_item_body',
        headerText: 'Title',
        textAlign: 'Left',
        width: '120'
    },
]