import React, { useState } from 'react';
import { FaUser, FaLock, FaPhone, FaPhoneSquare, FaPhoneAlt, FaEnvelope, FaCheck } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Users from '../Users';

const Register = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorRegistering, setErrorRegistering] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        if(password == confirmPassword){
            console.log(firstName);
            console.log(lastName);
            console.log(email);
            console.log(password);
            console.log(confirmPassword);
        }else{
            setErrorRegistering('Passwords don\'t match');
        }
    }
  return (
    <>
        {/* CONTAINER  */}
        <div className="bg-gradient-to-r from-blue-300 via-blue-40 to-blue-800 block h-screen items-center justify-center p-4 md:flex">
            {/* LOGIN TILE  */}
            <div className="
            bg-gray-100
              bg-cover bg-image 
              flex flex-col 
              items-center 
              max-w-screen-lg 
              overflow-hidden 
              rounded-lg 
              shadow-lg 
              text-gray-600 
              w-full 
              md:flex-row">
                {/* TEXT AREA  */}
                <div className="
                backdrop-filter 
                flex 
                  flex-col 
                  items-center 
                  justify-center 
                  p-20 
                  md:w-1/2">
                  <img src="https://firebasestorage.googleapis.com/v0/b/kasas-1-0.appspot.com/o/BackgroundImages%2Fflightbag.png?alt=media&token=85c9a67a-668e-4880-b728-1ca676259975" className='h-fit' alt="" />
                </div>

                {/* FORM AREA  */}
                <div className="bg-white flex flex-col items-center p-4 space-y-8 w-full md:w-1/2">
                    {/* WELCOME MESSAGE  */}
                    <div className="flex flex-col items-center">
                        <h1 className="font-medium text-black-400 text-xl">
                            Welcome to FlightBag 
                        </h1>
                        <p>Register your account</p>
                    </div>
                    {/* INPUT FIELDS  */}
                    <form onSubmit={handleSubmit} className="flex flex-col items-center space-y-4">
                        <div className="relative">
                            <span className="absolute flex inset-y-0 items-center pl-4 text-gray-400"><FaUser /></span>
                            <input onChange={event => setFirstName(event.target.value)} required className='border border-gray-300 outline-none placeholder-gray-400 pl-9 pr-4 py-1 rounded-md transition focus:ring-2 focus:ring-green-300'  placeholder='First Name...' type='text'/>
                        </div>
                        <div className="relative">
                            <span className="absolute flex inset-y-0 items-center pl-4 text-gray-400"><FaUser /></span>
                            <input onChange={event => setLastName(event.target.value)} required className='border border-gray-300 outline-none placeholder-gray-400 pl-9 pr-4 py-1 rounded-md transition focus:ring-2 focus:ring-green-300'  placeholder='Last Name...' type='text'/>
                        </div>
                        <div className="relative">
                            <span className="absolute flex inset-y-0 items-center pl-4 text-gray-400"><FaUser /></span>
                            <input onChange={event => setEmail(event.target.value)} required className='border border-gray-300 outline-none placeholder-gray-400 pl-9 pr-4 py-1 rounded-md transition focus:ring-2 focus:ring-green-300'  placeholder='Email...' type='text'/>
                        </div>
                        <div className="relative">
                            <span className="absolute flex inset-y-0 items-center pl-4 text-gray-400"><FaLock /></span>
                            <input onChange={event => setPassword(event.target.value)} required className='border border-gray-300 outline-none placeholder-gray-400 pl-9 pr-4 py-1 rounded-md transition focus:ring-2 focus:ring-green-300'  placeholder='Password...' type='password'/>
                        </div>
                        <div className="relative">
                            <span className="absolute flex inset-y-0 items-center pl-4 text-gray-400"><FaLock /></span>
                            <input onChange={event => setConfirmPassword(event.target.value)} required className='border border-gray-300 outline-none placeholder-gray-400 pl-9 pr-4 py-1 rounded-md transition focus:ring-2 focus:ring-green-300' placeholder='Confirm Password...' type='password'/>
                        </div>

                        <button  className="bg-blue-400 font-medium flex flex-row items-center px-3 py-1 rounded-md shadow-md text-white transition hover:bg-green-500 w-full" type='submit'>
                            <FaCheck className='mr-5'/> REGISTER
                        </button>
                        {
                            (errorRegistering != '') 
                            ? <span className='text-red-600 text-sm'>{ errorRegistering }</span> : ''
                        }
                        
                    </form>
                    {/* LINKS  */}
                    <div className="flex flex-row justify-between w-1/2">
                      <Link to='/login'>
                        <span className="text-gray-400 text-sm">Login</span>
                      </Link>
                      <Link to='/recover'>
                        <span className="text-gray-400 text-sm italic">Forgot Password?</span>
                      </Link>
                    </div>

                </div>
            </div>
        </div>
    </>
  )
}

export default Register