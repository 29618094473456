// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// IMPORT AUTH 
import { getAuth } from 'firebase/auth';
// IMPORT FIRESTORE 
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBEiNQ_EujvWdA0_buS7zgh_rjp3LJSenQ",
  authDomain: "kasas-1-0.firebaseapp.com",
  databaseURL: "https://kasas-1-0.firebaseio.com",
  projectId: "kasas-1-0",
  storageBucket: "kasas-1-0.appspot.com",
  messagingSenderId: "738491199443",
  appId: "1:738491199443:web:af1b35be37d7e0c290c926",
  measurementId: "G-0ZSDKP017Z"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app); 

export default app;