import React, { useState } from 'react';
import { GridComponent, 
  ColumnsDirective,
  ColumnDirective,
  Filter, 
  Page,
  Search, 
  Toolbar,
  Resize, 
  Sort,
  Edit, 
  Inject, 
  ContextMenu,
  ExcelExport,
  PdfExport} from '@syncfusion/ej2-react-grids';
import { ordersData, contextMenuItems, ordersGrid } from '../data/dummy';
import { stationData, stationGrid } from '../data/station';
import { Header } from '../components';
import { DatabaseService } from '../contexts/DatabaseContext';
import { useEffect } from 'react';

const Station = () => {

  const { getStations } = DatabaseService();

  // STATE 
  const [stations, setStations] = useState([]);

  const fetchStations = async () => {
    try{
      const stationList = await getStations();
      const finalStationList = stationList.docs.map((doc) => (doc.data()));
      setStations(finalStationList);
      console.log(stations);

    }catch(err){
      console.log(err.message);
    }
  }
// *******************************
  useEffect(() => {
    fetchStations();
  }, []);

  useEffect(() => {
    console.log(stations);
  }, [stations]);
// *******************************

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <Header category='Page' title='Station'/>
      <GridComponent 
      id='gridComponent'
      dataSource={stations}
      allowPaging
      allowSorting
      toolbar={['ExcelExport']}
      allowExcelExport>
        <ColumnsDirective>
        {
          stationGrid.map((item, index) => (
            <ColumnDirective key={index} { ...item}/>
          ))
        }
        </ColumnsDirective>
        <Inject services={[Toolbar, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit, PdfExport]} />

      </GridComponent>
      
    </div>
  )
}

export default Station