
// PREFIX IMAGE ON GRID 
export const gridAircraftImage = (props) => (  
    <div className="">
        <img 
        className=" rounded-xl h-20 md:ml-3 bg-slate-100 p-4 shadow-sm"
            src="https://firebasestorage.googleapis.com/v0/b/kasas-1-0.appspot.com/o/BackgroundImages%2FKASAS_logo_grey.png?alt=media&token=c0492803-06ea-4b4f-a704-6e30ef1fbd7f" 
            alt="aircraft" 
        />
    </div>
);

export const aircraftGrid = [
    {
        headerText: 'Icon',
        template: gridAircraftImage, 
        textAlign: 'Center',
        width: '120'
    },
    {
        field: 'model',
        headerText: 'Model',
        width: '150',
        textAlign: 'Center'

    },
    {
        field: 'reg_code',
        headerText: 'Country Code',
        width: '150',
        editType: 'dropdownedit',
        textAlign: 'Center',
    },
    {
        field: 'registration',
        headerText: 'Registration Code',
        width: '150',
        editType: 'dropdownedit',
        textAlign: 'Center',
    },
    {
        field: 'serial_number',
        headerText: 'Serial Number',
        width: '150',
        editType: 'dropdownedit',
        textAlign: 'Center',
    },
    {
        field: 'series',
        headerText: 'Series',
        width: '150',
        editType: 'dropdownedit',
        textAlign: 'Center',
    },

]




export const aircraftData = [

    {
        model: 'Dornier 228',
        reg_code: '5Y',
        registration: 'BRX',
        serial_number: '7004',
        series: '100'
    },
    {
        model: 'Dornier 228',
        reg_code: '5Y',
        registration: 'BTY',
        serial_number: '7005',
        series: '100'
    },
    {
        model: 'Dornier 228',
        reg_code: '5Y',
        registration: 'CDE',
        serial_number: '7006',
        series: '100'
    },
    {
        model: 'Dornier 228',
        reg_code: '5Y',
        registration: 'JCB',
        serial_number: '7008',
        series: '100'
    },
    {
        model: 'Dornier 228',
        reg_code: '5Y',
        registration: 'EDC',
        serial_number: '7007',
        series: '100'
    },
    {
        model: 'Dornier 228',
        reg_code: '5Y',
        registration: 'KJE',
        serial_number: '8003',
        series: '100'
    },
    {
        model: 'Dornier 228',
        reg_code: '5Y',
        registration: 'EGH',
        serial_number: '2391',
        series: 200
    }
]