import React, { useState, useEffect } from 'react';
import { 
  GridComponent, 
  ColumnsDirective, 
  ColumnDirective, 
  Resize, 
  Sort, 
  ContextMenu,
  Toolbar,
  Filter, 
  Page, 
  Edit, 
  Inject,
  ExcelExport
} from '@syncfusion/ej2-react-grids';

import { preflightsGrid } from '../data/preflights';
import { DatabaseService } from '../contexts/DatabaseContext';

import { Header } from '../components';


const Preflights = () => {

  // STATE 
  const [preflights, setPreflights] = useState([]);
  const [preflightsId, setPreflightsId] = useState([]);

  // FIRESTORE GETTER 
  const { getPreflightRiskAssessments } = DatabaseService();

  // FETCH PREFLIGHTS 
  const fetchPreflights = async () => {
    try{
      const preflightsList = await getPreflightRiskAssessments();
      const finalPreflightsList = preflightsList.docs.map((doc) => (doc.data()));
      const finalPreflightsListId = preflightsList.docs.map((doc) => ({data: doc.data(), id: doc.id}));

      setPreflights(finalPreflightsList);
      setPreflightsId(finalPreflightsListId);
      // console.log(preflights);
    }catch(err){
      console.log(err.message);
    }
  }

  // ******************************
  useEffect(() => {
    fetchPreflights();
  }, []); 
// ******************************
  useEffect(() => {
    // console.log(preflights)
  }, [preflights]);

// ******************************  

// console.log(prefligtsId)
// console.log(preflights)
// prefligtsId.forEach((idd) => {
//   console.log(idd.id);
//   console.log(idd['id']);
//   console.log(idd.data);
// })

// console.log(preflightsId)
const finalData = preflightsId.map((withId) => {
  withId.data["docId"] = withId.id
  return withId.data
})

console.log(finalData)


  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <Header category='Page' title='Preflight Risk Assessments' />
      <GridComponent
        id='gridComponent'
        dataSource={finalData.sort((a, b) => a.createdOn - b.createdOn)}
        // dataSource={preflights.sort((a, b) => a.createdOn - b.createdOn)}
        allowPaging
        allowSorting
        toolbar={[ExcelExport]}
        allowExcelExport
      >
        <ColumnsDirective>
          {
            preflightsGrid.map((item, index) => (  
              <ColumnDirective key={index} {...item} />
            ))
          }
        </ColumnsDirective>
        <Inject services={[Toolbar, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit]} />


      </GridComponent>
      
    </div>
  )
}

export default Preflights