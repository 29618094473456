import { Link } from "react-router-dom"


const gridPreflight = (props) => (
    <Link to={props.docId} className='text-blue-400 hover:underline'>
        <h2 className="">{props['Submitting Officer']}</h2>
    </Link>
)
export const preflightsGrid = [
    {
        headerText: 'Name',
        width: '120',
        textAlign: 'Left',
        template: gridPreflight
    }, 
    {
        field: 'createdOn',
        headerText: 'Date',
        textAlign: 'Left',
        width: '120'
    }, 
    {
        field: 'departure',
        headerText: 'Departure',
        textAlign: 'Left',
        width: '120'
    }, 
    {
        field: 'destination',
        headerText: 'Destination',
        textAlign: 'Left',
        width: '120'
    }, 
]