import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Header } from '../components'
import { DatabaseService } from '../contexts/DatabaseContext'
import { useParams } from 'react-router-dom';
import TableOne from '../components/TableOne';
import moment from 'moment'
import { Button } from 'flowbite-react';

const PreflightsData = (props) => {

  const pageId = useParams();
  const documentId = pageId.id;



  //   FIRESTORE GETTER 
  const { getOnePreflightRiskAssessment } = DatabaseService();

  //   STATE 
  const [preflight, setPreflight] = useState({});

  //   FETCH PREFLIGHT 
  const fetchPreglight = async () => {
    try {
      const preflightDoc = await getOnePreflightRiskAssessment(documentId);
      const orderdDoc = preflightDoc.data();
      setPreflight(orderdDoc);
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchPreglight();
  }, []);

  useEffect(() => {
    // console.log(preflight);
  }, [preflight]);


  if (preflight) {
    const total = Object.entries(preflight)
      .filter(([key, value]) => [
        'Score'
      ])
  }

  if (preflight) {
    const getData = Object.entries(preflight)
      .filter(([key, value]) => ![
        'aircraft',
        'station',
        'Submitting Officer',
        'createdOn',
        'departure',
        'destination',
        'officer_id',
        'license',
        'pic_hours_on_type',
        'hours_on_type',
        'total_pic_hours'
      ].includes(key))
      .sort()
      .map(pair => {
        const key = pair[0]
        const value = pair[1]
        return (
          <TableOne key={key.Rest} data={key} value={value} />
          // <tbody className='bg-slate-200'>
          //     <tr className='mb-2'>
          //         <td key={key.officer_id}>{key}</td>
          //         <td key={key.officer_id}>{value}</td>
          //     </tr>
          // </tbody>
        )
      })

    const date = preflight.createdOn;

    const theScore = Object
      .entries(preflight)
      .reduce((t, [k, v]) => t + (k.endsWith(' Score') ? + v : 0), 0);

    const handlePrint = () => {
      window.print();
    }


    return (
      <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <Header category='Preflights' title='Preflights Data' />

        <div className="bg-gray-50 flex flex-row justify-end items-end">
          <Button className='px-6 noprint' onClick={handlePrint} >Print</Button>
        </div>
        <div className="w-full rounded-lg shadow-md  flex md:flex-row gap-2">
          <div className="shadow-lg md:w-1/2 px-5 py-3">
            <ul className='font-bold'>
              <li className='mb-3'>Captain: <span className='font-light'>{preflight['Submitting Officer']}</span> </li>
              <li className='mb-3'>Date: <span className='font-light'>{moment(date).format('dddd, MMMM Do YYYY, h:mm:ss a')}</span> </li>
              <li className='mb-3'>Total Score: <span className='font-light'>{theScore}</span> </li>
            </ul>
          </div>
          <div className="md:w-1/2 px-5 py-3">
            <ul className='font-bold'>
              <li className='mb-3'>Aircraft: <span className='font-light'>{preflight['aircraft']}</span> </li>
              <li className='mb-3'>Station: <span className='font-light'>{preflight['station']}</span> </li>
              <li className='mb-3'>Departure: <span className='font-light'>{preflight['departure']}</span> </li>
              <li className='mb-3'>Destination: <span className='font-light'>{preflight['destination']}</span> </li>
            </ul>
          </div>
        </div>

        {/* TABLE  */}
        <table className="table-auto w-full text-left mt-4 striped">
          <thead className='bg-gray-300'>
            <tr>
              <th className='py-4 w-1/2'>Item</th>
              <th className='py-4 w-1/2'>Comment/Score</th>
            </tr>
          </thead>

          {getData}

        </table>

      </div>
    )


  }




}

export default PreflightsData
