

const gridStation = (props) => (  
    <div className="flex items-center gap-2">
        <img src={props.flag} 
        alt="station" 
        className="rounded-full w-10 h-10"/>
        {/* <p>Station Name</p> */}
    </div>
);

export const stationGrid = [
    {
        headerText: 'Flag',
        width: '150',
        template: gridStation,
        textAlign: 'Left'
    },
    {
        headerText: 'Station',
        field: 'name', 
        width: '150',
        textAlign: 'Left'
    },
    {
        headerText: 'Country', 
        field: 'country',
        width: '150',
        textAlign: 'Left'
    },
]

