import React from 'react'

const TableOne = ({data, value}) => {
  return (
    <tbody className='bg-slate-200'>
        <tr className='mb-2'>
            <td className='font-bold' key={data.officer_id}>{data}</td>
            <td key={data.officer_id}>{value}</td>
        </tr>
    </tbody>
  )
}

export default TableOne