import React, { useState, useEffect } from 'react';
import { 
  GridComponent, 
  ColumnsDirective, 
  ColumnDirective, 
  Resize, 
  Sort, 
  ContextMenu,
  Toolbar,
  Filter, 
  Page, 
  Edit, 
  Inject,
  ExcelExport
 } from '@syncfusion/ej2-react-grids';
import { DatabaseService } from '../contexts/DatabaseContext';
import { handoverItemsGrid  } from '../data/handoveritems';

import { Header } from '../components';

const HandoverItems = () => {

  // STATE 
  const [handoverItems, setHandoverItems] = useState ([]);
  // FIRESTORE GETTER 
  const { getHandoverItems } = DatabaseService();

  // FETCH HANDOVER ITEMS 
  const fetchHandoverItems = async () => {
    try{
      const handoverItemsList = await getHandoverItems();
      const finalHandoverItemsList = handoverItemsList.docs.map((doc) => (doc.data()));
      setHandoverItems(finalHandoverItemsList);
      console.log(handoverItems);

    }catch(err){
      console.log(err.message);
    }
  }

  useEffect(() => {
    fetchHandoverItems();
  }, []);
  
  return (
    <div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl">
      <Header category='Page' title='Handover Items' />
      <GridComponent
        id='gridComponent'
        dataSource={handoverItems.sort((a, b) => a.handover_item_code - b.handover_item_code)}
        allowPaging
        allowSorting
        toolbar={[ExcelExport]}
        allowExcelExport
      >
        <ColumnsDirective>
          {
            handoverItemsGrid.map((item, index) => ( 
              <ColumnDirective key={index} {...item}/>
            ))
          }
        </ColumnsDirective>
        <Inject services={[Toolbar, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit]} />

      </GridComponent>
    </div>
  )
}

export default HandoverItems