import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import { Navbar, Footer, Sidebar, ThemeSettings } from './components';
import { Dashboard, Handovers, Preflights, Users, Calendar, Bar, ColorMapping, Area, Line, Pie, Pyramid} from './pages';
import './App.css';

import { useStateContext } from './contexts/ContextProvider';
import Login from './pages/auth/Login';
import RootPage from './pages/RootPage';

const App = () => {

  const {activeMenu} = useStateContext();
  return (
    <div>
      <BrowserRouter>
        <RootPage />
      </BrowserRouter>
    </div>
  )
}

export default App