import React from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  Search,
  Toolbar,
  ExcelExport,
  PdfExport,
  Edit,
  Inject,
} from "@syncfusion/ej2-react-grids";

import { employeesData, employeesGrid } from "../data/dummy";
import { userData, usersGrid } from "../data/users";
import { Header } from "../components";
import { DatabaseService } from "../contexts/DatabaseContext";
import { useState } from "react";
import { useEffect } from "react";
import {
  Button,
  Checkbox,
  Dropdown,
  Label,
  Modal,
  Radio,
  Select,
  TextInput,
} from "flowbite-react";

const Users = () => {
  const { getUsers } = DatabaseService();

  // STATE
  const [users, setUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [department, setDepartment] = useState("");
  const [position, setPosition] = useState("");
  const [license, setLicense] = useState("");
  const [total_hours, setTotalHours] = useState("");
  const [total_pic_hours, setTotalPicHours] = useState("");
  const [pic_hours_on_type, setPicHoursOnType] = useState("");
  const [hours_on_type, setHoursOnType] = useState("");
  const [type_of_user, setTypeOfUser] = useState("");
  const [company, setCompany] = useState("");
  const [profile_photo, setProfilePhoto] = useState("");

  const fetchUsers = async () => {
    try {
      const userList = await getUsers();
      const finalUserList = userList.docs.map((doc) => doc.data());
      setUsers(finalUserList);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    console.log(users.length);
  }, [users]);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleClick = () => {};

  return (
    <div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Page" title="Users" />
      <button
        className="bg-blue-500 px-6 py-3 text-white font-bold rounded-sm"
        onClick={handleShow}
      >
        Add User
      </button>
      <GridComponent
        id="gridComponent"
        dataSource={users.sort((a, b) => a.first_name - b.first_name)}
        allowPaging
        allowSorting
        toolbar={["Search"]}
        width="auto"
        allowExcelExport
      >
        <ColumnsDirective>
          {usersGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Search, Page, Toolbar]} />
      </GridComponent>

      <Modal show={show} onClose={handleClose}>
        <Modal.Header>Add a User</Modal.Header>
        <Modal.Body>
          <form className="flex flex-col gap-4">
            <div className=" w-full flex flex-row gap-2">
              <div className="w-1/2">
                <div className="mb-2 block">
                  <Label htmlFor="first_name" value="First Name" />
                </div>
                <TextInput
                  // onChange={(e) => {
                  //   setFirstName(e.target.value);
                  // }}
                  id="first_name"
                  type="text"
                  placeholder="Name..."
                  required={true}
                />
              </div>

              <div className="w-1/2">
                <div className="mb-2 block">
                  <Label htmlFor="last_name" value="Last Name" />
                </div>
                <TextInput
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  id="last_name"
                  type="text"
                  placeholder="Name..."
                  required={true}
                />
              </div>
            </div>

            <div className=" w-full flex flex-row gap-2">
              <div className="w-1/2">
                <div className="mb-2 block">
                  <Label htmlFor="email" value="Email" />
                </div>
                <TextInput
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  id="email"
                  type="email"
                  placeholder="email@example.com"
                  required={true}
                />
              </div>

              <div className="w-1/2">
                <div className="mb-2 block">
                  <Label htmlFor="phone_number" value="Phone Number" />
                </div>
                <TextInput
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                  }}
                  id="phone_number"
                  type="tel"
                  placeholder="Phone..."
                  required={true}
                />
              </div>
            </div>

            <div>
              <div className="mb-2 block">
                <Label htmlFor="password" value="Password" />
              </div>
              <TextInput
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                id="password"
                type="password"
                required={true}
              />
            </div>

            <div className=" w-full flex flex-row gap-2">
              <div className="w-1/2 bg-gray-200 p-3">
                <fieldset className="flex flex-col gap-4" id="radio">
                  <legend>Select Department</legend>
                  <div className="flex items-center gap-2">
                    <Radio
                      id="flight_crew"
                      name="department"
                      value="Flight Crew"
                      defaultChecked={true}
                    />
                    <Label htmlFor="united-state">Flight Crew</Label>
                  </div>

                  <div className="flex items-center gap-2">
                    <Radio
                      id="operations"
                      name="department"
                      value="Operations"
                    />
                    <Label htmlFor="operations">Operations</Label>
                  </div>

                  <div className="flex items-center gap-2">
                    <Radio
                      id="maintenance"
                      name="department"
                      value="Maintenance"
                    />
                    <Label htmlFor="maintenance">Maintenance</Label>
                  </div>
                </fieldset>
              </div>

              <div className="w-1/2 p-3 bg-gray-300">
                <div className="w-1/2">
                  <fieldset className="flex flex-col gap-4" id="radio">
                    <legend>Select Position</legend>
                    <div className="flex items-center gap-2">
                      <Radio
                        id="captain"
                        name="position"
                        value="Captain"
                        defaultChecked={true}
                      />
                      <Label htmlFor="captain">Captain</Label>
                    </div>

                    <div className="flex items-center gap-2">
                      <Radio
                        id="co-pilot"
                        name="position"
                        value="Co-Pilot"
                        defaultChecked={true}
                      />
                      <Label htmlFor="co-pilot">Co-Pilot</Label>
                    </div>

                    <div className="flex items-center gap-2">
                      <Radio
                        id="engineer"
                        name="position"
                        value="Engineer"
                        defaultChecked={true}
                      />
                      <Label htmlFor="engineer">Engineer</Label>
                    </div>

                    <div className="flex items-center gap-2">
                      <Radio
                        id="operations"
                        name="position"
                        value="Operations"
                      />
                      <Label htmlFor="operations">Operations</Label>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>

            <div className=" w-full flex flex-row gap-2">
              <div className="w-1/2">
                <div className="mb-2 block">
                  <Label htmlFor="total_hours" value="Total hours" />
                </div>
                <TextInput
                onChange = {(e) => {setTotalHours(e.target.value)}}
                  id="total_hours"
                  type="text"
                  placeholder="Total Hours..."
                  required={true}
                />
              </div>

              <div className="w-1/2">
                <div className="mb-2 block">
                  <Label htmlFor="pic_hours_on_type" value="PIC Hrs On Type" />
                </div>
                <TextInput
                onChange={(e) => {setPicHoursOnType(e.target.value)}}
                  id="pic_hours_on_type"
                  type="text"
                  placeholder="PIC Hrs On Type..."
                  required={true}
                />
              </div>

              <div className="w-1/2">
                <div className="mb-2 block">
                  <Label htmlFor="total_pic_hours" value="Total PIC Hours" />
                </div>
                <TextInput
                onChange={(e) => {setTotalPicHours(e.target.value)}}
                  id="total_pic_hours"
                  type="text"
                  placeholder="Total PIC Hours..."
                  required={true}
                />
              </div>
            </div>

            <div className=" w-full flex flex-row gap-2">
              <div className="w-1/2">
                <div className="mb-2 block">
                  <Label htmlFor="hours_on_type" value="Hours On Type" />
                </div>
                <TextInput
                onChange={(e) => {setHoursOnType(e.target.value)}}
                  id="hours_on_type"
                  type="text"
                  placeholder="Hours On Type..."
                  required={true}
                />
              </div>

              <div className="w-1/2">
                <div id="select">
                  <div className="mb-2 block">
                    <Label htmlFor="license" value="License" />
                  </div>
                  <Select id="license" required={true}>
                    <option>ATPL</option>
                    <option>CPL</option>
                    <option>PIC</option>
                  </Select>
                </div>
              </div>
            </div>

            <Button type="submit">Submit</Button>
          </form>
        </Modal.Body>

        {/* <Modal.Footer>
          <Button onClick={handleShow}>I accept</Button>
          <Button color="gray" onClick={handleClose}>
            Decline
          </Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default Users;
