import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Header } from '../components';
import TableOne from '../components/TableOne';
import { DatabaseService } from '../contexts/DatabaseContext';
import moment from 'moment';
import { Button } from 'flowbite-react';

const HandoversData = (props) => {

  // STATE 
  const [handover, setHandover] = useState({});

  // GET PAGE ID 
  const pageId = useParams();
  const documentId = pageId.id;

  // FIRESTORE GETTER 
  const { getOneHandover } = DatabaseService();

  // FETCH HANDOVER 
  const fetchHandover = async () => {
    try{
      const handoverDoc = await getOneHandover(documentId);
      const orderDoc = handoverDoc.data();
      setHandover(orderDoc);
    }catch(err){
      console.log(err.message);
    }
  }

  // USE EFFECT 
  useEffect(() => {
    fetchHandover();
  }, []);

  useEffect(() => {
    console.log(handover);
  }, [handover]);

  if(handover){
    const getData = Object.entries(handover)
    .filter(([key, value]) => ![
      'aircraft', 
      'station', 
      'departing_officer',
      'incoming_officer',
      'handover_status',
      'position_handover',
      'created_on',
      'incoming_officer_id',
      'outgoing_officer_id'
    ].includes(key))
    .sort()
    .map(pair => {
      const key = pair[0]
      const value = pair[1]
      
      return(
        <TableOne key={key.aircraft} data={key} value={value} />
      )
    })

    const handlePrint = () => {
      window.print();
    }


    const date = handover.created_on;

    return (
      <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
          <Header category='Page' title='Handovers Data' />
          <div className="bg-gray-50 flex flex-row justify-end items-end">
            <Button className='px-6 noprint' onClick={handlePrint} >Print</Button>
          </div>
          <div className="w-full rounded-lg shadow-md  flex md:flex-row gap-2">
            <div className="shadow-lg md:w-1/2 px-5 py-3">
              <ul className='font-bold'>
                <li className='mb-3'>Outgoing: <span className='font-light'>{handover['departing_officer']}</span></li>
                <li className='mb-3'>Incoming: <span className='font-light'>{handover['incoming_officer']}</span></li>
                <li className='mb-3'>Date: <span className='font-light'>{ moment(date).format('dddd, MMMM Do YYYY, h:mm:ss a')}</span></li>
              </ul>
            </div>
            <div className=" md:w-1/2 px-5 py-3">
              <ul className='font-bold'>
                <li className='mb-3'>Aircraft: <span className='font-light'>{handover['aircraft']}</span></li>
                <li className='mb-3'>Station: <span className='font-light'>{handover['station']}</span></li>
                <li className='mb-3'>Status: <span className='font-light'>{handover['handover_status']}</span></li>
              </ul>
            </div>
          </div>
  
          {/* TABLE  */}
          <table className="table-auto w-full text-left mt-4 striped">
            <thead className='bg-gray-300'>
              <tr>
                <th className='py-4'>Item</th>
                <th className='py-4'>Comment/Score</th>
              </tr>
            </thead>
            { getData }
          </table>
      </div>
    )
  }

}

export default HandoversData