import React, { useState, useEffect } from 'react';
import { 
  GridComponent, 
  ColumnsDirective, 
  ColumnDirective, 
  Resize, 
  Sort, 
  ContextMenu,
  Toolbar,
  Filter, 
  Page, 
  Edit, 
  Inject,
  ExcelExport
} from '@syncfusion/ej2-react-grids';

import { handoversGrid } from '../data/handovers';

import { DatabaseService } from '../contexts/DatabaseContext';
import { Header } from '../components';

const Handovers = () => {

  // STATE 
  const [handovers, setHandovers] = useState([]);
  const [handoversId, setHandoversId] = useState([]);

  // FIRESTORE GETTER 
  const { getHandovers } = DatabaseService();

  // FETCH HANDOVERS 
  const fetchHandovers = async () => {
    try{
      const handoversList = await getHandovers();
      const finalHandoversList = handoversList.docs.map((doc) => (doc.data()));

      const finalHandoversListId = handoversList.docs.map((doc) => ({data: doc.data(), id: doc.id}));
      setHandovers(finalHandoversList);
      setHandoversId(finalHandoversListId);
      console.log(handovers);
    }catch(err){
      console.log(err.message);
    }
  }
// ******************************
  useEffect(() => {
    fetchHandovers();
  }, []); 
// ******************************
  useEffect(() => {
    console.log(handovers)
  }, [handovers]);

// ******************************  

const finalData = handoversId.map((withId) => {
  withId.data['docId'] = withId.id;
  return withId.data;
})
  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <Header category='Page' title='Handovers' />

      <GridComponent
        id='gridComponent'
        dataSource={finalData.sort((a, b) => a.created_on - b.created_on)}
        allowPaging
        allowSorting
        toolbar={[ExcelExport]}
        allowExcelExport
      >
        <ColumnsDirective>
          {
            handoversGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))
          }
        </ColumnsDirective>
        <Inject services={[Toolbar, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit]} />
      </GridComponent>

      
    </div>
  )
}

export default Handovers