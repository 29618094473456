import React, { createContext, useContext, useEffect, useState } from "react";
import {
    createUserWithEmailAndPassword, 
    signInWithPhoneNumber,
    signInWithEmailAndPassword,
    signOut, 
    onAuthStateChanged, 
    applicationVerifier,
    RecaptchaVerifier,
    signInAnonymously, 
    sendPasswordResetEmail
} from 'firebase/auth';

import { auth } from '../config/firebase';

 export const AuthContext = createContext()

 export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState({});

    // CREATE USER 
    const createUser = (email, password) => {
        return createUserWithEmailAndPassword(auth, email, password);
    };

    // SIGN IN ANON 
    const signInAnon = () => {
        return signInAnonymously(auth);
    };

    // SIGN OUT 
    const logout = () => {
        return signOut(auth);
    };

    // SIGN IN 
    const signIn = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password);
    };

    // PASSWORD RECOVERY 
    const recoverPassword = (email) => {
        return sendPasswordResetEmail(auth, email);
    };

 
    // THE USE EFFECT 
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            console.log(currentUser);
            setUser(currentUser);
        });
        return () => {
            unsubscribe();
        }
    }, [])
    return (
        <AuthContext.Provider value={{ createUser, user, logout, signIn, signInAnon, recoverPassword }}>
            { children }
        </AuthContext.Provider>
    )
 }

 export const UserAuth = () => {
    return useContext(AuthContext)
 }