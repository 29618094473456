import { Link } from 'react-router-dom';
import moment from 'moment';


const gridHandover = (props) => (
    <Link to={props.docId} className='text-blue-400 hover:underline'>
        <h2 className="">{props['incoming_officer']}</h2>
    </Link>
)

const timeTemplate = (props) => (
    // <h3>{ moment(props.created_on).format('dddd, MMMM Do YYYY, h:mm:ss a') }</h3>
    <h3>{ moment(props.created_on).format('MMM Do YYYY, h:mm:ss a') }</h3>
)
export const handoversGrid = [
    {
        headerText: 'Incoming', 
        width: '120',
        textAlign: 'Left',
        template: gridHandover
    },
    // {
    //     headerText: 'Another',
    //     width: '120',
    //     textAlign: 'Left',
    //     template: timeTemplate
    // },
    {
        field: 'departing_officer',
        headerText: 'Departing', 
        textAlign: 'Left',
        width: '120'
    },
    {
        field: 'aircraft',
        headerText: 'Aircraft', 
        textAlign: 'Left',
        width: '120'
    },
    {
        field: 'created_on',
        headerText: 'Date', 
        textAlign: 'Left',
        width: '120'
    },
    {
        field: 'station',
        headerText: 'Station', 
        textAlign: 'Left',
        width: '120'
    },
    {
        field: 'handover_status',
        headerText: 'Status', 
        textAlign: 'Left',
        width: '120'
    },
]