

const gridUserProfile = (props) => (
    <div className="flex items-center gap-2">
        <div className="rounded-full w-10 h-10 bg-cyan-500 text-white text-bold flex items-center justify-center">
            {
                <span>{ props.first_name.charAt(0)}.{ props.last_name.charAt(0)} </span>
            }
        </div>

        <p>{ props.first_name } { props.last_name }</p>
    </div>
);
export const usersGrid = [
    {
        headerText: 'Staff',
        width: '150',
        template: gridUserProfile,
        textAlign: 'Left'
    },
    {
        field: 'Name',
        headerText: '',
        width: '0',
        textAlign: 'Center'
    },
    {
        field: 'position',
        headerText: 'Position',
        width: '170',
        textAlign: 'Center'
    },
    {
        field: 'department',
        headerText: 'Department',
        width: '170',
        textAlign: 'Left'
    },
    {
        field: 'license',
        headerText: 'License',
        width: '170',
        textAlign: 'Left'
    },
]

export const userData = [
    {
        Name: 'David',
        Title: 'Captain',
        Country: 'Kenya',
        Department: 'Crew',
        Sport: 'The Sport'
    },
    {
        Name: 'David',
        Title: 'Captain',
        Country: 'Kenya',
        Department: 'Crew',
        Sport: 'The Sport'
    },
    {
        Name: 'David',
        Title: 'Captain',
        Country: 'Kenya',
        Department: 'Crew',
        Sport: 'The Sport'
    },
    {
        Name: 'David',
        Title: 'Captain',
        Country: 'Kenya',
        Department: 'Crew',
        Sport: 'The Sport'
    },
    {
        Name: 'David',
        Title: 'Captain',
        Country: 'Kenya',
        Department: 'Crew',
        Sport: 'The Sport'
    },
    {
        Name: 'David',
        Title: 'Captain',
        Country: 'Kenya',
        Department: 'Crew',
        Sport: 'The Sport'
    },
]