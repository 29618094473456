import React, { useEffect, useState } from 'react';
import { GoPrimitiveDot } from 'react-icons/go';
import { Stacked, Pie, Button, SparkLine } from '../components';
import { earningData, SparkLineAreaData, ecomPieChartData } from '../data/dummy';
import { UserAuth } from '../contexts/AuthContext';

import { useNavigate } from 'react-router-dom';
import { DatabaseService } from '../contexts/DatabaseContext';
import { Link } from 'react-router-dom';
import { MdOutlineSupervisorAccount } from 'react-icons/md';
import { FiBarChart } from 'react-icons/fi';
import { HiOutlineRefresh } from 'react-icons/hi';
import { BsBoxSeam } from 'react-icons/bs';

const Dashboard = () => {
  // NAVIGATION 
  const navigate = useNavigate();
  // DESTRUCTURE LOG OUT 
  const { logout } = UserAuth();

  // TO REVIEW 
  const { 
    getUsers,
    getAircraft, 
    getStations,
    getHandovers, 
    getPreflightRiskAssessments
     } = DatabaseService();

  // STATE DATA 
  const [list, setList] = useState([]);
  const [aircraft, setAircraft] = useState([]);
  const [stations, setStations] = useState([]);
  const [users, setUsers] = useState([]);
  const [handovers, setHandovers] = useState([]);
  const [preflights, setPreflights] = useState([]);


  // **1** LOGOUT FUNCTION 
  const handleLogout = async () => {
    try{
      await logout();
      navigate('/login');
      console.log('You are logged out');
    }catch(err){
      console.log(err.message);
    }
  }
  // **1**

  // **2** FETCH AIRCRAFT 
  const fetchAircraft = async () => {
    try{
      const aircraftList = await getAircraft();
      const finalAircraftList = aircraftList.docs.map((doc) => ({data: doc.data(), id: doc.id}));
      setAircraft(finalAircraftList);
      console.log(aircraft);
    }catch(err){
      console.log(err.message);
    }
  }
  // **2** 

  // **3** FETCH STATIONS  
  const fetchStations = async () => {
    try{
      const stationsList = await getStations();
      const finalStations = stationsList.docs.map((doc) => ({data: doc.data(), id: doc.id}));
      setStations(finalStations);
      console.log(stations);
    }catch(err){
      console.log(err.message);
    }
  }
  // **3** 

  // **4** FETCH USERS 
  const fetchUsers = async () => {
    try{
      const userList = await getUsers();
      const finalUserList = userList.docs.map((doc) => (doc.data()));
      setUsers(finalUserList);
    }catch(err){
      console.log(err.message);
    }
  }
  // **4**


  // **5** FETCH HANDOVERS
  const fetchHandovers = async () => {
    try{
      const handoversList = await getHandovers();
      const finalHandoversList = handoversList.docs.map((doc) => (doc.data()));
      setHandovers(finalHandoversList);
      console.log(handovers);
    }catch(err){
      console.log(err.message);
    }
  } 
  // **5** 

  // **6** FETCH PREFLIGHTS 
  const fetchPreflights = async () => {
    try{
      const preflightsList = await getPreflightRiskAssessments();
      const finalPreflightsList = preflightsList.docs.map((doc) => (doc.data()));
      setPreflights(finalPreflightsList);
      console.log(preflights);
    }catch(err){
      console.log(err.message);
    }
  }
  // **6** 



  // **2** FETCH POSITIONS 
  const fetchPositions = async () => {
    try{
      const userList = await getUsers();
      const final = userList.docs.map((doc) => ({data: doc.data(), id: doc.id}));
      setList(final);
    }catch(err){
      console.log(err.message);
    }
  }
  // **2**

  // **3** USE EFFCET 
  useEffect(() => {
    fetchPositions();
    fetchAircraft();
    fetchStations();
    fetchUsers();
    fetchHandovers();
    fetchPreflights();
  }, []); 

  useEffect(() => {

  }, [stations, aircraft, users, preflights, handovers])
  // **3**



  return (
    <div className="mt-12">
      {/* <button onClick={handleLogout}>Log Out</button> <br /> */}
      <div className="flex flex-wrap lg:flex-nowrap justify-center">
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full lg:w-80 p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
          <div className="flex justify-between items center">
            <div>
              <p className='font-bold text-gray-400'>Users</p>
              <p className='text-2xl'> { users ? users.length : 'Loading...' } </p>
            </div>
          </div>
          <div className="mt-6">
            <Link to='/users'><Button color='white' bgColor='blue' text='View' borderRadius='10px' size='md'/></Link>
          </div>
        </div>
        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">

        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56 p-4 pt-9 rounded-2xl">
            <button type='button' style={{ color: '#03C9D7', backgroundColor: '#E5FAFB'}}
            className='text-2xl opacity-0.9 rounded-full p-4 hover:drop-shadow-xl'>
              <MdOutlineSupervisorAccount />
            </button>
            <p className="mt-3">
              <span className="text-lg font-semibold">{ handovers ? handovers.length : 'Loading...' }</span>
              <span className={`text-sm text-red-600 ml-2`}>
                {/* {item.percentage} */}
              </span>
            </p>
            <p className='text-sm text-gray-400 mt-1'>Handovers</p>

          </div>

        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56 p-4 pt-9 rounded-2xl">
            <button type='button' style={{ color: '#03C9D7', backgroundColor: '#E5FAFB'}}
            className='text-2xl opacity-0.9 rounded-full p-4 hover:drop-shadow-xl'>
              <FiBarChart />
            </button>
            <p className="mt-3">
              <span className="text-lg font-semibold">{ preflights ? preflights.length : 'Loading...' }</span>
              <span className={`text-sm text-red-600 ml-2`}>
                {/* {item.percentage} */}
              </span>
            </p>
            <p className='text-sm text-gray-400 mt-1'>Preflight Risk Assessments</p>

          </div>

          <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56 p-4 pt-9 rounded-2xl">
              <button type='button' style={{ color: '#03C9D7', backgroundColor: '#E5FAFB'}}
              className='text-2xl opacity-0.9 rounded-full p-4 hover:drop-shadow-xl'>
                <HiOutlineRefresh />
              </button>
              <p className="mt-3">
                <span className="text-lg font-semibold">{ aircraft ? aircraft.length : 'Loading...' }</span>
                <span className={`text-sm text-red-600 ml-2`}>
                  {/* {item.percentage} */}
                </span>
              </p>
              <p className='text-sm text-gray-400 mt-1'>Aircraft</p>

          </div>

          <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56 p-4 pt-9 rounded-2xl">
              <button type='button' style={{ color: '#03C9D7', backgroundColor: 'gb(235, 250, 242)'}}
              className='text-2xl opacity-0.9 rounded-full p-4 hover:drop-shadow-xl'>
                <BsBoxSeam />
              </button>
              <p className="mt-3">
                <span className="text-lg font-semibold">{ stations ? stations.length : 'Loading...' }</span>
                <span className={`text-sm text-red-600 ml-2`}>
                  {/* {item.percentage} */}
                </span>
              </p>
              <p className='text-sm text-gray-400 mt-1'>Stations</p>

          </div>


          {/* { earningData.map((item) => (
            <div key={item.title} className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56 p-4 pt-9 rounded-2xl">
              <button type='button' style={{ color: item.iconColor, backgroundColor: item.iconBg}}
              className='text-2xl opacity-0.9 rounded-full p-4 hover:drop-shadow-xl'>
                { item.icon }
              </button>
              <p className="mt-3">
                <span className="text-lg font-semibold">{item.amount}</span>
                <span className={`text-sm text-${item.pcColor} ml-2`}>
                  {item.percentage}
                </span>
              </p>
              <p className='text-sm text-gray-400 mt-1'>{ item.title }</p>

            </div>
          ))} */}
        </div>
      </div>

      <div className="flex gap-10 flex-wrap justify-center">
      <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg m-3 p-4 rounded-2xl md:w-780  ">
          <div className="flex justify-between">
            <p className="font-semibold text-xl">Progress Updates</p>
            <div className="flex items-center gap-4">
              <p className="flex items-center gap-2 text-gray-600 hover:drop-shadow-xl">
                <span>
                  <GoPrimitiveDot />
                </span>
                <span>Submitted</span>
              </p>
              <p className="flex items-center gap-2 text-green-400 hover:drop-shadow-xl">
                <span>
                  <GoPrimitiveDot />
                </span>
                <span>Accepted</span>
              </p>
            </div>
          </div>
          <div className="mt-10 flex gap-10 flex-wrap justify-center">
            <div className=" border-r-1 border-color m-4 pr-10">
              <div>
                <p>
                  <span className="text-3xl font-semibold">23</span>
                  <span className="p-1.5 hover:drop-shadow-xl cursor-pointer rounded-full text-white bg-green-400 ml-3 text-xs">
                    23%
                  </span>
                </p>
                <p className="text-gray-500 mt-1">Handed in </p>
              </div>
              <div className="mt-8">
                <p className="text-3xl font-semibold">3</p>

                <p className="text-gray-500 mt-1">Handed out</p>
              </div>

              <div className="mt-5">
                {/* <SparkLine currentColor={currentColor} id="line-sparkLine" type="Line" height="80px" width="250px" data={SparklineAreaData} color={currentColor} /> */}
              </div>
              <div className="mt-10">
                <Button
                  color="white"
                  // bgColor={currentColor}
                  text="Download Report"
                  borderRadius="10px"
                />
              </div>
            </div>
            <div>
              {/* <Stacked currentMode={currentMode} width="320px" height="360px" /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard